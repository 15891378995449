<template>

  <div class="app-container">

    <div style="margin-top: 30px;margin-left: 100px;margin-right: 100px;">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="spuName">
              <el-input v-model="form.spuName" placeholder="请输入商品名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="上架状态" prop="publishStatus">
              <el-radio-group v-model="form.publishStatus">
                <el-radio v-for="dict in publishDict" :key="dict.value"
                  :label="parseInt(dict.value)">
                  {{dict.label}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.isMoreSpec == '0'">
          <el-col>
            <el-form-item label="规格名称" prop="spuName">
              <el-input style="width: 280px;" v-model="form.skuInfo.skuName" placeholder="请输入商品名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品类型" prop="skuInfo.spuType">
              <el-radio-group v-model="form.spuType" @change="changeSpuType">
                <el-radio :label="1">商品</el-radio>
                <el-radio :label="2">水票</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品分类" prop="catalogId">
              <el-select v-model="form.catalogId" placeholder="请选择分类" @change="handlerCatalog">
                <el-option v-for="item in catalogList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.spuType == '2'">
          <el-form-item label="赠送规则" prop="skuSendNums">
            <el-button type="primary" @click="addSendNum" size="mini">添加赠送规则</el-button>
            <el-table :data="form.skuSendNums" style="width: 100%" size="mini">
              <el-table-column prop="payNum" label="购买数量" >
                <template #default="scope">
                  <el-input-number v-model="scope.row.payNum" :min="1" ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column prop="sendNum" label="赠送数量" >
                <template #default="scope">
                  <el-input-number v-model="scope.row.sendNum" :min="1" ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column>
                <template #default="scope">
                  <el-button @click.native.prevent="deleteSend(scope.$index, form.skuSendNums)" type="text" size="small">
                      移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-row>
        <el-row v-if="form.isMoreSpec == 0">
          <el-form-item label="商品主图" prop="skuInfo.skuDefaultImg">
            <imageUpload v-model="form.skuInfo.skuDefaultImg" :limit="parseInt(1)" />
            <span class="el-upload__tip">请上传比例为： <b style="color: #f56c6c">1 X 1</b> 的图片规格</span>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="商品轮播图" prop="supImages">
            <imageUpload v-model="form.supImages" />
          </el-form-item>
        </el-row>

        <el-form-item v-if="form.spuType == 1" label="多规格" prop="isMoreSpec">
          <el-switch v-model="form.isMoreSpec" @change="getAttrSaleOptions" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>        


        <!-- 单规格 -->
        <div v-if="form.isMoreSpec == 0">
          <el-row>
            <el-form-item label="库存">
              <el-input-number v-model="form.skuInfo.inventory"></el-input-number>
            </el-form-item>
            <el-form-item label="销售价格">
              <el-input-number v-model="form.skuInfo.price" :precision="2"></el-input-number>
            </el-form-item>
            <el-form-item label="划线价">
              <el-input-number v-model="form.skuInfo.linePrice" :precision="2"></el-input-number>
            </el-form-item>
            <el-form-item label="置顶排序">
              <el-input-number v-model="form.spuSort" :min="0" :max="99" ></el-input-number>
            </el-form-item>                     
          </el-row>
        </div>

        <!-- 多规格 -->
        <div v-else>
          <el-row>
            <el-form-item v-for="(attr, index) in form.saleAttrs" :label="attr.name" :key="attr.key">
              <el-tag :key="tag" v-for="tag in attr.value" closable :disable-transitions="false"
                @close="handleSaleAttrClose(tag,index)">
                {{tag}}
              </el-tag>
              <el-input class="input-new-tag" v-if="attr.inputVisible" v-model="inputValue"
                ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(index)"
                @blur="handleInputConfirm(index)">
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput(index)">+ 添加属性值
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="refreshSaleAttrs">重新加载</el-button>
              <el-button type="success" @click="inintSkuDataList">立即生成</el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item>
              <el-table :data="form.saleData" style="width: 100%" size="mini">
                <el-table-column v-for="site in form.clos" :key="site.prop" :prop="site.prop"
                  :label="site.label" width="150px" align='center'>
                </el-table-column>
                <el-table-column label="商品主图" width="80">
                  <template #default="scope">
                    <el-upload class="avatar-uploader2" :action="uploadUrl" :show-file-list="false"
                      :on-success="(response, file, fileList) =>handleSaleImageSuccess(response, file, fileList,scope.$index)"
                      :before-upload="beforeSaleImageUpload" :headers="headers">
                      <img v-if="scope.row.skuDefaultImg" :src="scope.row.skuDefaultImg"
                        class="avatar2">
                      <i v-else class="el-icon-plus avatar-uploader-icon2"></i>
                    </el-upload>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称">
                  <template #default="scope">
                    <el-input v-model="scope.row.skuName" align='center' />
                  </template>
                </el-table-column>
                <el-table-column label="标题">
                  <template #default="scope">
                    <el-input v-model="scope.row.skuTitle" align='center' />
                  </template>
                </el-table-column>
                <el-table-column label="库存" width="180">
                  <template #default="scope">
                    <el-input v-model="scope.row.inventory" align='center' />
                  </template>
                </el-table-column>
                <el-table-column label="销售价格" width="180">
                  <template #default="scope">
                    <el-input v-model="scope.row.price" align='center' placeholder="0" />
                  </template>
                </el-table-column>
                <el-table-column label="划线价格" width="180">
                  <template #default="scope">
                    <el-input v-model="scope.row.linePrice" align='center' placeholder="0" />
                  </template>
                </el-table-column>
                <el-table-column label="商品底价" width="180">
                  <template #default="scope">
                    <el-input v-model="scope.row.floorPrice" align='center' placeholder="0" />
                  </template>
                </el-table-column>                
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="deleteRow(scope.$index, form.saleData)" type="text"
                      size="small">
                      移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-row>
        </div>

        <!-- <el-form-item label="一级分佣">
          <el-input-number v-model="form.skuInfo.oneSale" :precision="2" :min="0" :max="1 - form.skuInfo.twoSale" :step="0.01"></el-input-number>
          <span style="font-size: 12px; color: red; margin-left: 10px;">销售金额（%）</span>
        </el-form-item> 
        <el-form-item label="二级分佣">
          <el-input-number v-model="form.skuInfo.twoSale" :precision="2" :min="0" :max="1 - form.skuInfo.oneSale" :step="0.01"></el-input-number>
          <span style="font-size: 12px; color: red; margin-left: 10px;">销售金额（%）</span>
        </el-form-item> -->
        <el-form-item label="提成比例">
          <el-input-number v-model="form.skuInfo.goodsSale" :precision="2" :min="0" :max="1 - form.skuInfo.goodsSale" :step="0.01"></el-input-number>
          <span style="font-size: 12px; color: red; margin-left: 10px;">销售金额（%）</span>
        </el-form-item>    
        

        <el-form-item label="商品详情">
          <editor v-model="form.spuDescription" :min-height="192" />
        </el-form-item>

        <el-form-item style="text-align: center;margin-left:-100px;margin-top:10px;">
          <el-button type="primary" @click="submitForm()">提交</el-button>
          <el-button @click="close()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>


  </div>

</template>

<script>
import api from '@/api/commonApi'
import { getToken } from "@/utils/auth"

export default {
  name: "AddSpuInfo",
  dicts: ['spu_publish_status', 'is_free_postage'],
  data () {
    return {
      uploadUrl: process.env.VUE_APP_SERVER_URL + "/api/common/upload", // 上传的图片服务器地址
      headers: {
        token: getToken()
      },
      // 分类列表
      catalogList: [],    
      // 表单参数
      form: {
        weight: 0.00,
        publishStatus: 0,
        isUseIntegral: 0,
        maxIntegral: 0,
        isMoreSpec: 0,
        spuType: 1,
        isFreePostage: 1,
        auditStatus: 1,
        spuAddType: 0,
        isStick: 0,
        isSpecial: 0,
        spuSort: 99,
        saleAttrs: [],
        subjectIds: [],
        spuDescription: '', // 商品详情
        skuSendNums: [], // 水票赠送
        skuInfo: {
          id: '',
          skuName: null,
          skuSubtitle: '', // 副标题
          catalogId: '',
          skuTitle: null,
          price: 0.00,
          skuDefaultImg: null,
          skuSwiperImg: null,
          inventory: 0,
          linePrice: 0.00,
          skuType: 1,
          skuServiceType: 1,
          platformMoney: 0.10,
          skuRebate: 0.00,
          oneSale: 0.20, // 一级分佣
          twoSale: 0.10, // 二级分佣
          goodsSale: 0.00, // 销售金额
        }
      },
      // 表单校验
      rules: {
        spuName: [{
          required: true,
          message: "商品标题不能为空",
          trigger: "blur"
        }],
        publishStatus: [{
          required: true,
          message: "请选择状态",
          trigger: "change"
        }],
        catalogId: [{
          required: true,
          message: "请选择分类",
          trigger: "change"
        }],
        supImages: [{
          required: true,
          message: "请上传图片",
          trigger: "blur"
        }],
        // 'skuInfo.skuDefaultImg': [{
        //   required: true,
        //   message: "请上传主图",
        //   trigger: "blur"
        // }],
        'skuInfo.skuType': [{
          required: true,
          message: "请选择所属分类",
          trigger: "blur"
        }],
      },
      // 分类树
      baseCatalogOptions: undefined,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //品牌参数
      brandOptions: [],
      // 规格参数
      dynamicValidateForm: [

      ],
      dynamicTags: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: '',
      // 弹出层标题
      title: '选择店铺',
      // 是否弹窗
      shopOpen: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ownerName: null,
        shopName: null,
        selfStatus: null,
        state: 1,
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 店铺表格数据
      shopList: [],
      // 专题数
      subjectList: [],
      // 上下架
      publishDict: [
        { label: '下架', value: '0' },
        { label: '上架', value: '1' }
      ],
    }
  },
  created () {
    this.initialize()
    this.getCatalogList()
  },
  methods: {
    // 获取商品详情
    initialize() {
      api.getGoodInfo(this.$route.query.tableId).then(res => {
        console.log(res);
        this.form = res
      })
    },
    // 获取商品分类
    getCatalogList() {
      api.catalogList().then(res => {
        this.catalogList = res
      })
    },
    // 重新加载销售属性
    refreshSaleAttrs () {
      this.getAttrSaleOptions()
      this.form.saleData = []
      this.form.clos = []
    },
    // 生成sku列表
    inintSkuDataList () {
      let list = new Array()
      let clos = new Array()
      for (let i = 0; i < this.form.saleAttrs.length; i++) {
        list.push(this.form.saleAttrs[i].value)
        clos.push({
          attrId: this.form.saleAttrs[i].attrId,
          prop: 'skuColumn' + i,
          label: this.form.saleAttrs[i].name
        })
      }
      this.form.clos = clos
      let skuList = this.calcDescartes(list)
      let skuDataTableList = new Array()
      // 生成数据
      if (skuList.length > 0) {
        for (let i = 0; i < skuList.length; i++) {
          let skuData = this.inintSkuDataTableList(skuList[i], clos)
          skuDataTableList.push(skuData)
        }
      } else {
        let skuData = this.inintSkuDataTableList(skuList, clos)
        skuDataTableList.push(skuData)
      }
      this.form.saleData = skuDataTableList
      this.$forceUpdate()
    },
    //销售属性移除
    handleSaleAttrClose (tag, index) {
      this.form.saleAttrs[index].value.splice(this.form.saleAttrs[index].value.indexOf(tag), 1)
    },
    //显示当前行销售属性框
    showInput (index) {
      this.form.saleAttrs[index].inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput + index
      })
    },
    // 添加销售属性
    handleInputConfirm (index) {
      if (this.inputValue) {
        if (this.form.saleAttrs[index].value.indexOf(this.inputValue) == -1) {
          this.form.saleAttrs[index].value.push(this.inputValue)
        }
      }
      this.form.saleAttrs[index].inputVisible = false
      this.inputValue = ''
    },
    // 销售属性笛卡尔集
    calcDescartes (array) {
      if (array.length < 2) return array[0] || []
      return array.reduce((total, currentValue) => {
        let res = []
        total.forEach(t => {
          currentValue.forEach(cv => {
            if (t instanceof Array) // 或者使用 Array.isArray(t)
              res.push([...t, cv])
            else
              res.push([t, cv])
          })
        })
        return res
      })
    },
    // 生产表格数据
    inintSkuDataTableList (skuList, clos) {
      let skuData = {}
      skuData['id'] = ''
      skuData['skuName'] = this.form.spuName
      skuData['skuTitle'] = this.form.spuName
      skuData['price'] = '0.00'
      skuData['skuDefaultImg'] = ''
      skuData['inventory'] = '0'
      skuData['linePrice'] = '0.00'

      let modelNameEn = ''
      let modelNameCn = []
      let attrs = []
      for (let j = 0; j < clos.length; j++) {
        let attrValue = ''
        // 判断是否是数组
        if (skuList instanceof Array) {
          attrValue = skuList[j]
        } else {
          attrValue = skuList
        }
        skuData[clos[j].prop] = attrValue
        let attr = {}
        attr['name'] = clos[j].label
        attr['value'] = attrValue
        attr['attrId'] = clos[j].attrId
        attrs.push(attr)
      }
      skuData['attrs'] = attrs
      return skuData
    },
    // 规格列表 行图片添加成功
    handleSaleImageSuccess (response, file, fileList, index) {
      this.form.saleData[index].skuDefaultImg = response.data.url
    },
    beforeSaleImageUpload (file) {
      let hz = file.name.split('.').pop()
      const isLt5M = file.size / 1024 / 1024 < 5
      let flag = true
      if (hz != 'jpg' && hz != 'png' && hz != 'webp') {
        this.$message.error('只能选择JPG,PNG,webp文件!')
        flag = false
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!')
        flag = false
      }
      return flag
    },
    //删除行sku
    deleteRow (index, rows) {
      this.form.saleData.splice(index, 1)
    },
    // 提交保存
    submitForm () {
      console.log(this.form);
      this.$refs["form"].validate(valid => {
        if (valid) {
          api.editGoods(this.form).then(response => {
            this.$message.success("编辑成功")
            if (response) {
              this.close()
            }
          })
        }
      })
    },
    /** 关闭按钮 */
    close () {
      let tabs = this.$store.state.menus.editableTabs;
      this.$store.state.menus.editableTabs = tabs.filter(tab => tab.name !== this.$store.state.menus.editableTabsValue);
      this.$router.push({
        path: "/pms/products",
        query: {
          t: Date.now(),
          pageNum: 1
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm("queryForm")
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.id)
    },
    handleSelect (data) {
      this.form.shopId = data.id
      if(data.id != '1469144053674569730'){
        this.form.skuInfo.skuType = 2
      }
      this.form.shopName = data.shopName
      this.shopOpen = false
    },
    // 获取所有销售属性
    getAttrSaleOptions () {
      if (this.form.isMoreSpec == 1) {
        if (this.form.catalogId != null && this.form.catalogId != '') {
          api.goodAttrInfo({
            "catalogId": this.form.catalogId,
          }).then(response => {
            console.log(response)
            this.form.saleAttrs = response
          })
        } else {
          this.form.isMoreSpec = 0
          this.$message.error('请选择分类!')
          return false
        }
      }
    },
    // 选择分类
    handlerCatalog(e) {
      console.log(e);
      this.form.skuInfo.catalogId = e
    },
    // 添加赠送规则
    addSendNum() {
      this.$nextTick(() => {
        this.form.skuSendNums.push({
          skuId: null,
          payNum: 1,
          sendNum: 1,
        })
      })
    },
    //删除行赠送规则
    deleteSend (index, rows) {
      this.form.skuSendNums.splice(index, 1)
    },
    // 选择类型
    changeSpuType(e) {
      if (e == 2) {
        this.form.isMoreSpec = 0
      }
    },
  }
}
</script>

<style scoped="scoped">
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.avatar-uploader2 .el-upload:hover {
  border-color: #b5b5b5;
}

.avatar2 {
  width: 60px;
  height: 60px;
  display: block;
}

.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>
